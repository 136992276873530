window.if208 = window.if208 || {};
(function ($) {
  if208.getIf208Api = function (successCallback, failureCallback) {
    let url = '/delegate/if208';
    $.ajax({
      type: 'GET',
      url: url,
      contentType: 'application/json',
      success: successCallback,
      failure: failureCallback
    })
  };

  if208.setHeaderOnContextChange = function () {
    var successCallback = function (data) {
      var loggedMsisdn = data['auth.msisdn'];
      var contextMsisdn = data['context.msisdn'];
      var contextMsisdnField = $('.user-context-msisdn.context-msisdn');
      var loggedMsisdnField = $('.user-context-msisdn.logged-msisdn');

      if (!contextMsisdn || loggedMsisdn === contextMsisdn) {
        contextMsisdnField.hide();
        loggedMsisdnField.show();

      } else {
        loggedMsisdnField.hide();
        var headerMsisdn = '0' + contextMsisdn.replace(/(\d{3})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
        contextMsisdnField.find('.tooltip-dots').text(headerMsisdn);
        contextMsisdnField.show();
      }
    };
    if208.getIf208Api(successCallback, function (error) {
      console.error(error);
    });
  }
}($));