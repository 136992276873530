window.LogoutPopups = window.LogoutPopups || {};
(function ($) {
  var popupVisible = [];

  var defaultTexts = {
    title: 'Pozor, automatick\u00E9 odhl\u00E1senie',
    body: '<p>O <span id=\"secondsToLogout\">p\u00E1r</span> sek\u00FAnd budete presmerovan\u00FD na hlavn\u00FA str\u00E1nku.</p>',
    button: 'Pokra\u010Dova\u0165 v pr\u00E1ci',
    link: 'Odhl\u00E1si\u0165 teraz'
  };

  LogoutPopups.appendPopup = function (popupId, onClose, textation) {
    let texts = $.extend({}, defaultTexts, textation);
    let markup =
    `<style>
      .fancybox-popup-content .progress {min-width:425px;}
      @media (max-width: 600px) {
        .fancybox-popup-content .progress {min-width:50px;}
      }
    </style>
    <div>
      <div id="${popupId}" class="fancybox-popup-content">
        <h2 id="${popupId}-title">${texts.title}</h2>
        <div id="${popupId}-body">${texts.body}</div>
        <div class="progress">
          <div id="${popupId}-progressbar" class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 0; background: #428bca; height: 100%; border-radius: 0;"></div>
        </div>
        <a id="${popupId}-button" href="#" class="btn btn-blue" onclick="$.fancybox.close()">${texts.button}</a>
        <a id="${popupId}-link" href="#" class="btn btn-link" onclick="$.fancybox.close()">${texts.link}</a>
      </div>
    </div>`;
    $(markup).appendTo(document.body);

    let popup = $('#' + popupId);
    popup.fancybox({
      href: '#' + popupId,
      closeClick: false,
      helpers: {
        overlay: {
          closeClick: false
        }
      },
      enableEscapeButton: false,
      keys: {
        close: 0
      },
      beforeClose: function () {
        popupVisible[popupId] = false;
        if (onClose) {
          onClose();
        }
      }
    });

    popup.click(function () {
      return !LogoutPopups.isOpen(popupId);
    });
  };

  LogoutPopups.openPopup = function (popupId) {
    $('#' + popupId).click();
    popupVisible[popupId] = true;
  };

  LogoutPopups.modifyPopup = function (popupId, texts) {
    let txt = $.extend(true, {}, defaultTexts, texts);
    $('#' + popupId + '-title').html(txt.title);
    $('#' + popupId + '-body').html(txt.body);
    $('#' + popupId + '-button').html(txt.button);
    $('#' + popupId + '-link').html(txt.link);
  };

  LogoutPopups.closePopups = function () {
    let fancybox = $.fancybox;
    if (typeof fancybox === 'undefined') {
      fancybox = $.fancybox;
    }
    fancybox.close();
  };

  LogoutPopups.isOpen = function (popupId) {
    return !!popupVisible[popupId];
  }
}($));
