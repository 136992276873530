/*
 * ===========================================================================
 * IBA CZ Confidential
 *
 * © Copyright IBA CZ 2015 ALL RIGHTS RESERVED
 * The source code for this program is not published or otherwise
 * divested of its trade secrets.
 * ===========================================================================
 *
 * Created by jakub.coufal, jan.skrabal@ibacz.eu.
 */

window.Authentication = window.Authentication || {};
(function ($) {
  var PORTAL_IDM_CONFIG_URL_POSTFIX = '/delegate/rest/loginidm/idmConfig';
  var PORTAL_AUTH_CONFIG_URL_POSTFIX = '/delegate/rest/loginidm/authConfig';
  var PORTAL_SESSION_INVALIDATION_POSTFIX = '/delegate/rest/loginidm/invalidateSession';
  var PORTAL_SELFCARE_URL_POSTFIX = '/moje-o2';
  var PORTAL_LOGOUT_URL_POSTFIX = '/c/portal/logout';
  var PORTAL_LOGOUT_REDIRECT_PARAM = '?referer=';
  var IDM_LOGOUT_REDIRECT_URL_POSTFIX = '/authenticationendpoint/logout.do?redirect_uri=';
  var POPUP_LOGOUT_ID = 'logoutPopup';
  var STORAGE_RESET_INTERVAL_MILLIS = 30000;
  var ABSOLUTE_URL_REGEX = new RegExp('^\\s*(?:[a-z0-9]+:)?//', 'i');
  var TIME_NOT_SET = null;
  var TICK_LENGTH = 1000;
  var MESSAGE_HASH = 'login-hash';

  var STORAGE_KEYS = {
    INACTIVITY_POPUP_TIMEOUT: 'inactivityPopupTimeout',
    TIME_TO_INACTIVITY_POPUP: 'timeToInactivityPopup',
    LOGOUT_TIMEOUT: 'logoutTimeout',
    TIME_TO_LOGOUT: 'timeToLogout',
    REDIRECT_URL: 'logoutRedirectUrl',
    LAST_TICK: 'lastTick',
    LOGOUT_IMMEDIATELY: 'logoutImmediately',
    AUTHENTICATION_CONFIGURATION: 'authConfig'
  };

  var IN_MEMORY_KEYS = {
    TIME_TO_INVOKED_POPUP: 'timeToInvokedPopup'
  };

  var defaults = {
    disable_sso: false,
    logout_timeout: 20000,
    inactivity_popup_timeout: 20000,
    invoked_popup_timeout: 10000,
    on_store: false,
    portal_location: `${window.location.protocol}//${window.location.hostname}`,
    redirect_url: `https://${window.location.hostname}${PORTAL_SELFCARE_URL_POSTFIX}`,
    logout_redirect_url: `https://${window.location.hostname}`,
    logout_url: `${window.location.protocol}//${window.location.hostname}${PORTAL_LOGOUT_URL_POSTFIX}`,
    onFail: function () {
    }
  };

  var timeToInvokedPopup = null;
  var lastTimes = [];

  var isSet = function (variable) {
    return typeof variable !== 'undefined' && variable != null;
  };

  /* not exported functions */
  var createState = function (data, options) {
    var uri = '';
    if (isSet(data.redirect_url)) {
      uri = data.redirect_url;
    } else {
      uri = options.redirect_url;
    }
    var state = {
      uri: uri,
      token: data.uuid
    };
    return encodeURIComponent(JSON.stringify(state));
  };

  var createHint = function (options) {
    var hint = {
      login_method: options.login_method,
      lang: options.lang,
      show_logo: options.show_logo,
      login_reason: options.show_message_login,
      smartid_reason: options.show_message_smartid,
      hash: options.hash,
      disable_sso: options.disable_sso
    };
    return encodeURIComponent(JSON.stringify(hint));
  };

  var redirectToIDM = function (data) {
    var url_location = data.url +
      '/oauth2/authorize?response_type=code&client_id=' + data.client +
      '&redirect_uri=' + data.callback +
      '&scope=PRODUCTION&state=' + data.state +
      '&login_hint=' + data.hint;

    if (data.login_method === 'smart_id') {
      url_location += '&reAuth=true';
    }

    window.location.href = url_location;
  };

  var isOnStore = function () {
    return defaults.on_store;
  };

  var doIfOnStore = function (callback) {
    if (isOnStore()) {
      callback();
    }
  };

  var createPopupSelector = function (comp) {
    var selector = '#' + POPUP_LOGOUT_ID;
    if (isSet(comp)) {
      selector = selector + '-' + comp;
    }
    return selector;
  };

  var invalidateSession = function (onSuccessCallback) {
    $.ajax({
      type: 'GET',
      url: defaults.portal_location + PORTAL_SESSION_INVALIDATION_POSTFIX,
      contentType: 'text/plain',
      success: function () {
        onSuccessCallback();
      }
    });
  };

  var performActionWithPortalCall = function (relativeUrl, onSuccessCallback, onFailCallback) {
    $.ajax({
      type: 'GET',
      url: defaults.portal_location + relativeUrl,
      cache: true,
      contentType: 'text/plain',
      dataType: 'JSON',
      success: function (data) {
        onSuccessCallback(data);
      },
      error: function () {
        if (isSet(onFailCallback)) {
          onFailCallback();
        } else {
          defaults.onFail();
        }
      }
    });
  };

  var loadAuthenticationConfiguration = function (onSuccessCallback) {
    performActionWithPortalCall(PORTAL_AUTH_CONFIG_URL_POSTFIX, function (data) {
      localStorage.setItem(STORAGE_KEYS.AUTHENTICATION_CONFIGURATION, JSON.stringify(data));
      onSuccessCallback(data);
    });
  };

  var getAuthenticationConfiguration = function () {
    var conf = localStorage.getItem(STORAGE_KEYS.AUTHENTICATION_CONFIGURATION);
    return JSON.parse(conf);
  };

  var isSignedIn = function () {
    var config = getAuthenticationConfiguration();
    return config.user_signed_in;
  };

  var getTimeFromStorage = function (key) {
    var val = localStorage.getItem(key);
    if (isSet(val)) {
      return parseInt(val);
    }
    return TIME_NOT_SET;
  };

  var disableLogoutCountdown = function () {
    localStorage.removeItem(STORAGE_KEYS.LOGOUT_TIMEOUT);
    localStorage.removeItem(STORAGE_KEYS.TIME_TO_LOGOUT);
  };

  Authentication.disableInactivityPopupCountdown = function () {
    localStorage.removeItem(STORAGE_KEYS.INACTIVITY_POPUP_TIMEOUT);
    localStorage.removeItem(STORAGE_KEYS.TIME_TO_INACTIVITY_POPUP);
  };

  var disableInvokedPopupCountdown = function () {
    timeToInvokedPopup = null;
  };

  var decrement = function (keyTimeLeft, disableFunction) {
    var lastValue = lastTimes[keyTimeLeft];
    var current = IN_MEMORY_KEYS.TIME_TO_INVOKED_POPUP === keyTimeLeft ? timeToInvokedPopup : getTimeFromStorage(keyTimeLeft);
    lastTimes[keyTimeLeft] = current;
    if (isSet(lastValue)) {
      if (current !== TIME_NOT_SET && lastValue === current) {
        lastValue = current - TICK_LENGTH;
        if (current > 0 && lastValue < 0) {
          lastValue = 0;
        }
        if (IN_MEMORY_KEYS.TIME_TO_INVOKED_POPUP === keyTimeLeft) {
          timeToInvokedPopup = lastValue;
        } else {
          localStorage.setItem(keyTimeLeft, lastValue);
        }
        lastTimes[keyTimeLeft] = lastValue;
      }
      if (current <= -1) {
        disableFunction();
      }
    }
  };

  var synchronizedTimeDecrement = function () {
    decrement(STORAGE_KEYS.TIME_TO_INACTIVITY_POPUP, Authentication.disableInactivityPopupCountdown);
    /*Invoked time to logout is in memory*/
    decrement(IN_MEMORY_KEYS.TIME_TO_INVOKED_POPUP, disableInvokedPopupCountdown);
    decrement(STORAGE_KEYS.TIME_TO_LOGOUT, disableLogoutCountdown);
  };

  var clearLocalStorage = function () {
    for (var property in STORAGE_KEYS) {
      if (STORAGE_KEYS.hasOwnProperty(property)) {
        localStorage.removeItem(STORAGE_KEYS[property]);
      }
    }
  };

  var storageStaleCheck = function () {
    var lastTickTime = getTimeFromStorage(STORAGE_KEYS.LAST_TICK);
    if (isSet(lastTickTime)) {
      var currentTime = (new Date()).getTime();
      if (currentTime - lastTickTime > STORAGE_RESET_INTERVAL_MILLIS) {
        clearLocalStorage();
      }
    }
    localStorage.setItem(STORAGE_KEYS.LAST_TICK, currentTime);
  };

  var updateLogoutPopupState = function (timeToLogout, logoutTimeout) {
    var popupIsOpen = LogoutPopups.isOpen(POPUP_LOGOUT_ID);
    if (isSet(timeToLogout) && timeToLogout > -1) {
      $(createPopupSelector('progressbar')).css('width', (timeToLogout * 100 / logoutTimeout) + '%');
      $('#secondsToLogout').html(Math.ceil(timeToLogout / 1000));
      if (!popupIsOpen) {
        LogoutPopups.openPopup('logoutPopup');
      }
    } else {
      $(createPopupSelector('progressbar')).css('width', 0);
      if (popupIsOpen) {
        LogoutPopups.closePopups();
      }
    }
  };

  var getRedirectUrl = function (urlEncode) {
    var redirectUrl = localStorage.getItem(STORAGE_KEYS.REDIRECT_URL);
    return sanitizeUrl(redirectUrl, urlEncode);
  };

  var logout = function () {
    localStorage.setItem(STORAGE_KEYS.LOGOUT_IMMEDIATELY, true);
    disableLogoutCountdown();
    if (isSignedIn()) {
      performActionWithPortalCall(PORTAL_IDM_CONFIG_URL_POSTFIX,
        function (data) {
          window.location = data.idm_url + IDM_LOGOUT_REDIRECT_URL_POSTFIX +
            encodeURIComponent(defaults.logout_url + PORTAL_LOGOUT_REDIRECT_PARAM + getRedirectUrl(true));
        }
      );
    } else {
      invalidateSession(function () {
        window.location = getRedirectUrl(false);
      })
    }
  }

  var updateLogoutPopup = function () {
    var logoutTimeout = getTimeFromStorage(STORAGE_KEYS.LOGOUT_TIMEOUT);
    var timeToLogout = getTimeFromStorage(STORAGE_KEYS.TIME_TO_LOGOUT);
    updateLogoutPopupState(timeToLogout, logoutTimeout);
  };

  var triggerActionIfNeeded = function () {
    if (getTimeFromStorage(STORAGE_KEYS.TIME_TO_INACTIVITY_POPUP) === 0) {
      Authentication.logout({ logout_timeout: 0 });
    }
    if (timeToInvokedPopup === 0) {
      Authentication.disableInactivityPopupCountdown();
      var configuration = getAuthenticationConfiguration();
      var lastTimeToLogout = $.extend(true, {}, defaults, configuration).logout_timeout;
      lastTimes[STORAGE_KEYS.TIME_TO_LOGOUT] = lastTimeToLogout;
      updateLogoutPopupState(lastTimeToLogout, lastTimeToLogout);
      localStorage.setItem(STORAGE_KEYS.LOGOUT_TIMEOUT, lastTimeToLogout);
      localStorage.setItem(STORAGE_KEYS.TIME_TO_LOGOUT, lastTimeToLogout);
    }
    if (getTimeFromStorage(STORAGE_KEYS.TIME_TO_LOGOUT) === 0) {
      logout();
    }
  };

  var tick = function () {
    /* If the logout countdown is still running and the flag immediate logout is set then logout immediately.
     It means that the countdown has finished in another tab. */
    if (isSet(lastTimes[STORAGE_KEYS.TIME_TO_LOGOUT]) && localStorage.getItem(STORAGE_KEYS.LOGOUT_IMMEDIATELY) === 'true') {
      logout();
    }

    storageStaleCheck();
    updateLogoutPopup();
    triggerActionIfNeeded();
    synchronizedTimeDecrement();
    setTimeout(tick, TICK_LENGTH);
  };

  var getUrlDomain = function (url) {
    var a = document.createElement('a');
    a.href = url;
    return a.hostname;
  };

  var sanitizeUrl = function (url, urlEncode) {
    if (ABSOLUTE_URL_REGEX.test(url)) {
      if (getUrlDomain(url) !== window.location.hostname) {
        var opts = $.extend(true, {}, defaults, getAuthenticationConfiguration());
        url = opts.logout_redirect_url;
      }
    }
    if (urlEncode) {
      return encodeURIComponent(url);
    }
    return url;
  }

  /* API functions */
  Authentication.init = function (options) {
    $.extend(true, defaults, options);
  };

  Authentication.logout = function (options) {
    loadAuthenticationConfiguration(function (data) {
      var opts = $.extend(true, {}, defaults, data);
      var timeout, redirectUrl;
      if (isSet(options)) {
        timeout = options.logout_timeout;
        redirectUrl = options.redirect_url;
      }
      if (!isSet(timeout) || timeout < 0) {
        timeout = opts.invoked_popup_timeout;
      }
      if (!isSet(redirectUrl)) {
        redirectUrl = opts.logout_redirect_url;
      }

      var logoutTimeout = getTimeFromStorage(STORAGE_KEYS.TIME_TO_LOGOUT);
      if (logoutTimeout === TIME_NOT_SET) {
        if (timeToInvokedPopup === TIME_NOT_SET || timeToInvokedPopup > timeout) {
          lastTimes[IN_MEMORY_KEYS.TIME_TO_INVOKED_POPUP] = timeToInvokedPopup;
          localStorage.setItem(STORAGE_KEYS.LOGOUT_IMMEDIATELY, false);
          timeToInvokedPopup = timeout;
          localStorage.setItem(STORAGE_KEYS.REDIRECT_URL, redirectUrl);
        }
      }
    });
  };

  Authentication.immediateLogout = function (options) {
    loadAuthenticationConfiguration(function (authData) {
      var url;
      if (isSet(options)) {
        url = options.redirect_url;
      }
      if (!isSet(url)) {
        url = $.extend(true, {}, defaults, authData).logout_redirect_url;
      }

      performActionWithPortalCall(PORTAL_IDM_CONFIG_URL_POSTFIX,
        function (data) {
          localStorage.setItem(STORAGE_KEYS.LOGOUT_IMMEDIATELY, true);
          disableLogoutCountdown();
          window.location = data.idm_url + IDM_LOGOUT_REDIRECT_URL_POSTFIX +
            encodeURIComponent(defaults.logout_url + PORTAL_LOGOUT_REDIRECT_PARAM + sanitizeUrl(url, true));
        }
      );
    });
  };

  Authentication.authenticate = function (options) {
    options = $.extend(true, {}, defaults, options);
    performActionWithPortalCall(PORTAL_IDM_CONFIG_URL_POSTFIX,
      function (data) {
        var redirectData = {
          url: data.idm_url,
          callback: data.callback_url,
          client: data.client_id,
          state: createState(data, options),
          hint: createHint(options),
          login_method: options.login_method
        };
        redirectToIDM(redirectData);
      },
      function () {
        options.onFail();
      }
    );
  };

  /* Initialization */
  $(function () {

    LogoutPopups.appendPopup(POPUP_LOGOUT_ID, function () {
      disableLogoutCountdown();
      doIfOnStore(function () {
        Authentication.disableInactivityPopupCountdown()
      });
    });

    $(createPopupSelector('link')).on('click', function () {
      logout();
      return false;
    });

    $(createPopupSelector('button')).on('click', function () {
      return false;
    });

    tick();
    $(window).on('message', function (e) {
      var event;
      if (typeof (e.originalEvent) !== 'undefined') {
        event = e.originalEvent;
      } else {
        event = e;
      }

      if (event.origin && event.origin.search(/https?:\/\/.*\.o2\.sk$/gi) < 0) {
        return;
      }

      if (event.data && event.data.event && event.data.event.indexOf(MESSAGE_HASH) >= 0) {
        Authentication.authenticate({
          login_method: 'hash',
          hash: event.data.hash
        });
      }

    });
  });
}($));
