/* 
 * ===========================================================================
 *  IBA CZ Confidential
 *  (c) Copyright IBA CZ 2012 ALL RIGHTS RESERVED
 *  The source code for this program is not published or otherwise
 *  divested of its trade secrets.
 * ===========================================================================
 */

/**
 * Load campaign after page load if not already loaded
 */
(function ($) {
  window.O2 = window.O2 || {};

  window.O2.CampaignCount = window.O2.CampaignCount || {
    config: {
      campaignCountUrl: ''
    }
  };

  O2.CampaignCount.init = function (config) {
    var that = this;

    that.config = $.extend({}, that.config, config);
    O2.CampaignCount.healthCheckFetchNumber();
  };

  O2.CampaignCount.healthCheckFetchNumber = function () {
    var that = this;

    if ($('.user-menu').length > 0 && !$('.user-menu').hasClass('loaded')) {
      var ajaxOptions = {
        async: true,
        dataType: 'text',
        url: that.config.campaignCountUrl,
        success: function (data) {
          if (data.length > 0 && parseInt(data) > 0) {
            $('.mojeo2-user-head .msg-tip, .mojeo2-menu .msg-tip').html(data);
            $('.mojeo2-user-head .number-badge, .mojeo2-menu .number-badge').html(data).removeClass('empty');
            $('.user-menu').addClass('loaded');
          }
        }
      };
      $.ajax(ajaxOptions);
    }
  };
}($));
