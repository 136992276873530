/*
 * ===========================================================================
 * IBA CZ Confidential
 *
 * © Copyright IBA CZ 2015 ALL RIGHTS RESERVED
 * The source code for this program is not published or otherwise
 * divested of its trade secrets.
 * ===========================================================================
 */

var MAX_SUGGESTOR_LIMIT = 100;

window.initTypeAhead = function(portletId, formName, streetField, townField, zipField, countryField) {
  onClickTypeAheadByStreet(portletId, formName, streetField, townField, zipField, countryField);
  onClickTypeAheadByTown(portletId, formName, streetField, townField, zipField, countryField);
}

function getInputSelector(portletId, formName, fieldName) {
  let form = '#' + portletId + ' form[formId=' + formName + ']';
  return form + ' :input[name="' + fieldName + '"]';
}

function urlReplace(type, portletId, formName, countryField) {
  return {
    url: '/eportal-liferaytheme/addressRegisterServlet?json=true&type=' + type + '&country=%ADDRESS_COUNTRY&q=%QUERY',
    countryField: countryField,
    portletId: portletId,
    formName: formName,
    replace: function (url, query) {
      if ($(this)[0].countryField === undefined || $(this)[0].countryField === null) {
        return url.replace('%QUERY', removeDiacritics(query)).replace('&country=%ADDRESS_COUNTRY', '');
      }
      return url.replace('%QUERY', removeDiacritics(query)).replace('%ADDRESS_COUNTRY', $(getInputSelector($(this)[0].portletId, $(this)[0].formName, $(this)[0].countryField)).val());
    }
  };
}

function onClickTypeAheadByTown(portletId, formName, streetField, townField, zipField, countryField) {
  $(document).ready(function () {
    var towns = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: urlReplace('town', portletId, formName, countryField),
      limit: MAX_SUGGESTOR_LIMIT
    });
    towns.initialize();

    // Callback function to update required fields when user selected suggested address
    let updateTownFields = function (evt, data) {
      $(getInputSelector(portletId, formName, townField)).typeahead('val', data.town);
      $(getInputSelector(portletId, formName, townField)).valid();
      $(getInputSelector(portletId, formName, zipField)).val(data.zip);
      $(getInputSelector(portletId, formName, zipField)).valid();
      $(getInputSelector(portletId, formName, townField)).addClass('filled');
      $(getInputSelector(portletId, formName, zipField)).addClass('filled');
    };

    let input = $(getInputSelector(portletId, formName, townField));
    input.typeahead(
      {
        // minLength: <your number>, Default is 1
        hint: true,
        highlight: true
      },
      {
        name: 'towns',
        displayKey: function (towns) {
          return (towns.town + ', ' + towns.zip);
        },
        source: towns.ttAdapter()
      }).on('typeahead:selected', updateTownFields).
      on('typeahead:autocompleted', updateTownFields);

    let hint = input.prev('input.tt-hint');
    hint.removeClass('filled');

    removeAllData(hint);

    moveLabelForTypeAhead($(getInputSelector(portletId, formName, townField)));
    moveTooltipForTypeAhead(input);
  });
}

function onClickTypeAheadByStreet(portletId, formName, streetField, townField, zipField, countryField) {
  $(document).ready(function () {
    var streets = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: urlReplace('street', portletId, formName, countryField),
      limit: MAX_SUGGESTOR_LIMIT
    });
    streets.initialize();

    // Callback function to update required fields when user selected suggested address
    var updateStreetFields = function (evt, data) {
      $(getInputSelector(portletId, formName, streetField)).typeahead('val', data.street);
      $(getInputSelector(portletId, formName, streetField)).valid();
      if ($(getInputSelector(portletId, formName, townField)).typeahead != null) {
        $(getInputSelector(portletId, formName, townField)).typeahead('val', data.town);
      } else {
        $(getInputSelector(portletId, formName, townField)).val(data.town);
      }
      $(getInputSelector(portletId, formName, townField)).valid();
      $(getInputSelector(portletId, formName, zipField)).val(data.zip);
      $(getInputSelector(portletId, formName, zipField)).valid();
      $(getInputSelector(portletId, formName, zipField)).addClass('filled');
      $(getInputSelector(portletId, formName, townField)).addClass('filled');
    };
    var $input = $(getInputSelector(portletId, formName, streetField));

    $input.typeahead({
      // minLength: <your number>, Default is 1
      hint: true,
      highlight: true
    },
      {
        name: 'streets',
        displayKey: function (streets) {
          return (streets.street + ', ' + streets.town + ', ' + streets.zip);
        },
        source: streets.ttAdapter()
      }).on('typeahead:selected', updateStreetFields)
      .on('typeahead:autocompleted', updateStreetFields);

    let hint = $input.prev('input.tt-hint');
    hint.removeClass('filled');

    removeAllData(hint);

    moveLabelForTypeAhead($(getInputSelector(portletId, formName, streetField)));
    moveTooltipForTypeAhead($input);
  });
}

function moveLabelForTypeAhead(input) {
  let label = input.parent().parent().find('label.control-label');
  label.detach();
  input.after(label);
}

function moveTooltipForTypeAhead(input) {
  let tooltip = input.parent().parent().find('.question-mark');
  tooltip.detach();
  input.parent().append(tooltip);
}

function removeAllData(object) {
  $.each(object.data(), function (i, v) {
    let name = i.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
    object.removeAttr('data-' + name);
  });
  object.removeData();  // clear the cache too
}
