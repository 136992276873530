window.dynatraceAlertMessageCheck = function () {
  $('div.alert[data-o2-role^="alert-"]:visible').each(function (index) {
    var portletId = $(this).parents('div.portlet').attr('id');
    var alertRole = $(this).attr('data-o2-role');
    var alertText = $(this).children('p').text();

    dtrum.reportError('Portlet ' + portletId + ' has shown following ' + alertRole + ' error: ' + alertText);
  });
};

$(document).ready(function () {
  if (window.dtrum !== undefined && window.dtrum !== null) {
    window.dynatraceAlertMessageCheck();
  }
});
